.nav-wrapper{
    display:flex;
    background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    height: 8vh;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    position:fixed;
    z-index: 5;
    .nav-container{
        width: 70vw;
        display:flex;
        justify-content: space-between;
        align-items: center;
        img{
            width:clamp(130px, 12vw, 250px);
            height:auto;
        }
        .logos{
            width: clamp(80px, 8.9vw, 170px);
            display: flex;
            flex-direction: row;
            align-items: last baseline;
            justify-content: space-between;

            filter: contrast(1.1);

            /*&::after {
                content: "";
                top: -2px;
                left: -2px;
                right: -2px;
                bottom: -2px;
                border: 1px solid white;
                transition: all .5s;
                animation: clippath 3s infinite linear;
                border-radius: 8px;
            } <- abandoned effect for filling border animation*/ 
            
            /*&::after {
                animation: clippath 3s infinite -1.5s linear;
            }*/
            svg{
                &:hover{
                    transform: scale(1.2);
                    transition: transform linear 0.15s;
                }
                width: clamp(22px,2.1vw,35px);
                height: 40%;
            }
        }
        }
}


  @media (max-width: 1023px) {
  .nav-wrapper{
    .nav-container{
    width: 75vw;
  }
}
  }
  
  @media (max-width: 767px) {
    .nav-wrapper{
      .nav-container{
      width: 81vw;
    }
  }
    }

 @media (max-width: 480px) {
        .nav-wrapper{
            height: 7vh;
          .nav-container{
          width: 88vw;
        }
      }
        }
  


