@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=League+Spartan:wght@100..900&display=swap');
$gray6: rgb(242,242,247);
$gray5: rgb(229,229,234);
$gray4: rgb(209,209,214);
$gray3: rgb(199,199,204);
$gray2: rgb(174,174,178);
$gray1: rgb(142,142,147);
$dark2: rgb(99,99,102);
$dark3: rgb(72,72,74);
$dark4: rgb(58,58,60);
$dark5: rgb(44,44,46);
$dark6: rgb(28,28,30);
$blue: rgb(0,122,255);
$brown: rgb(172,142,104);
$green: rgb(50,215,75);
$brown-light: rgb(162,132,94);