@keyframes strokeAnimation {
    100% {
      stroke-dashoffset: 50; /* You may need to adjust this value */
    }
  }
  
  .twitter-path {
    stroke-dasharray: 9;
    animation: strokeAnimation 7s cubic-bezier(0.75, 0, 0.25, 1) alternate infinite;
    stroke: #1DA1F2; /* White */
    stroke-width: 3;
    fill: none;
    stroke-linecap:square;
    stroke-linejoin: round;
  }

  .reddit-path {
    stroke-dasharray: 12;
    animation: strokeAnimation 7s cubic-bezier(0.75, 0, 0.25, 1) alternate infinite;
    stroke: #FF4500; /* White */
    stroke-width: 3;
    fill: none;
    stroke-linecap:round;
    stroke-linejoin: round;
  }

  .app-store-path {
    stroke-dasharray: 12;
     animation: strokeAnimation 7s cubic-bezier(0.75, 0, 0.25, 1) alternate infinite;
    stroke: #FFFFFF; /* White */
    stroke-width: 3;
    fill: none;
    stroke-linecap:round;
    stroke-linejoin: round;

  }