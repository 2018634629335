@import '../../styles/core';
.mask-wrapper{
    max-width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: $gray5;

}
.spacer-six{
    width: 100px;
    height: 6vh;
}
.spacer-flex-one{
    width: 100%;
    height: calc(1.5vw + 2.8vh);
}
.spacer-flex-two{
    width: 100%;
    height: calc(0.7vw + 1.4vh);
}
.spacer-nav{
    width: 100%;
    height: 8vh
}
.spacer-twelve{
    width: 100%;
    height: 12vh;
    
}

.panel-one-container{
    height:auto;
    width: auto;
    background-color: $gray6;
}

.intro-wrapper {
    max-width: 100%;
    height: auto;
    display:flex;
    justify-content:center;
    align-items:center;
    
    .intro-container{
        width: 50vw;
        height: 100%;
        font-size: clamp(6px,0.835vw,16px);
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        align-items:first baseline;
        text-align:left;
        line-height: 0;
        h1{
            color: $dark5;
        }
        h2{
            color: $gray2;
            font-weight: normal;
        }

    }
    .intro-buttons{
        width: 20vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Maximally separate the items vertically */
        align-items: flex-end; /* Align items to the right */
        gap:4.2vh;
        .app-logo{
            margin-bottom: auto;
            img{
            border-radius: 3vw;
            height:auto;
            width: clamp(70px, 8.9vw, 170px);

        }
        }
        .app-download{
            align-self: flex-end;
            img{
            height: auto;
            width: clamp(70px, 8.9vw, 170px);
  
            }
            &:hover{
                transform: scale(1.1,1.1);
                transition: transform ease-in 0.1s;
            }
        }
    }

}

.intro-links{
    text-decoration: none;
    padding-right: 5px;
    color: $dark5;
    text-shadow: -2px 3px 2px rgba(199, 199, 199, 0.87);

    &:hover{
        font-size: 1.10em;
        transition:  ease-in 0.1s;
    }
}

.arrow {
    padding-right: 20px;
    color: #2750B4;
    font-weight: 900;
    
}

.feature-header{
    font-size: clamp(8px,1vw, 21px);
    line-height: 0.5;
}
.feature-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin: auto;
  }
  
  .feature-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    background-color: $gray6;
    
    border-radius: 10px;
    padding: 5px;
  }
  
  /* This class reverses the order for the second row */
  .feature-row-reverse {
    flex-direction: row-reverse;
    background-color: $gray5;
  }
  
.feature-text-one, .feature-text-two {
    width: 50%; /* Adjust based on your layout needs */
    font-size: clamp(14px,1vw, 20px);
    
  }
  .feature-object-one{
    width: 30%;
    display:flex;
    justify-content: start;
    &:hover{
        transform: scale(1.05);
    }
  }
  .feature-object-two{
    width: 30%;
    display:flex;
    justify-content: end;
    &:hover{
        transform: scale(1.05);
    }
  }
  .feature-text-one{
    padding: 10px;
    color:  $dark3;
    .feature-description{
        line-height: 0.4;
        h4{
            color: $blue;
        }

        }

    .feature-bottom{
        width: 320px;
        align-self: last baseline;
        color: $dark5;
    }
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
    .feature-text-two{
        
        .feature-bottom{
            width: 230px;
            align-self: first baseline;
            color: $dark3;
        }
        .feature-description{
            line-height: 0.4;
            h4{
                color:$blue;
            }
            }
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    color: $dark5;

  }
  
  .feature-object-one img, .feature-object-two img {
    width: 320px;
    height: auto;
    border-radius: 10px; /* Styling for rounded corners */
  }

  .photos-wrapper{
    width: 70vw;
    display:flex;
    justify-content: center;
    margin: auto;
  }
  .photos-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Aligns items to the start of the container, useful if items have different heights */
    width: 100%;
  }
  
  .photos-column {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: $gray2;
    border-radius: 10px;
    align-items: center; /* Centers the content (image and text) within each column */
    width: calc(50% - 40px); /* Adjust width based on padding/gap desired. The subtraction accounts for equal spacing */
    img{
        width: clamp(100px,11vw,250px);
        height: auto;
        
        &:hover{
            width: clamp(110px,12vw,260px);
        }
        
    }
  }
  
  .photos-column img {
    max-width: 100%; /* Ensures the image does not exceed the column width */
    height: auto; /* Maintains the aspect ratio of the image */
    margin-bottom: 10px; /* Space between the image and the text */
  }
  
  .photos-column p {
    text-align: center; /* Center-aligns the text */
    font-size: clamp(14px,1vw, 20px);
    width: 20vw;
    color: $dark6;
  }
  .photos-column h3{
  font-size: clamp(14px,1.2vw, 20px);
    color: $gray6;
}
  .flex-why-wrapper{
    width: 70vw;
    margin: auto;
    display:flex;
    justify-content:center;
    align-items: center;
    height:auto;
  }
  .flex-why {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 15px;
  }
  
  .column-why {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the items horizontally within each column */
    width: calc(33.333% - 10px); /* Adjusts each column's width to be a third of the container minus the padding/gap */
    background-color: $dark2;
    border-radius: 10px;
    font-size: clamp(9px,0.9vw, 20px);
    color: $dark6;
    p {
        width: 60%;
        color: $gray6;
    }
  }
  

  
  .column-why p {
    text-align: center; /* Ensures the text is centered below the image */
  }
  .feature-section {
    background-color: $gray4;
  }
  .footer{
    max-width: 100vw;
    height: 4vh;
    display:flex;
    font-size: clamp(9px, 0.6vw, 12px);
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $gray2;
    color: $dark6;
    
    .footer-links{
        text-decoration: none;
        display:flex;
        flex-direction: row;
        width: auto;
        gap: 15px;
        color: $gray6;
        a:visited{
            color: $gray6;
        }
        a:link{
            color: $gray6;
        }
        a:active{
            color: $gray6;
        }
        }



    }
    
@media (max-width: 1023px) {
.intro-wrapper{
    .intro-container{
        width: 55vw;
        
        }
        .intro-buttons{
    
        }
    }
}


@media (max-width: 767px) {
.intro-wrapper{
    .intro-container{
        width: 60vw;

    }
    .intro-buttons{

    }

}
.feature-object-one  {

    img{
    width: 150px;
    height: 125px;}

  }
.feature-object-two {
img{
width:153px;
height: 130px;
}
}
.feature-wrapper{
width: 90vw;

}

.feature-text-one{
    font-size: 10px;
    .feature-bottom{
        width: 60%;
    }
}
.feature-wrapper{
    width: 90vw;
    margin: auto;
}
.feature-text-two{
    font-size: 10px;
    .feature-bottom{
        width: 80%;
    }
}
.photos-wrapper{
    width: 90vw;
}
.photos-column p {
    text-align: center; /* Center-aligns the text */
    font-size: 10px;
    width: 35vw;
  }
.photos-container{
    width: 100%;
}
.flex-why-wrapper{
    width: 90vw;
}
.flex-why{
    width: 100%;
    p{
        width: 80%;
    }
    padding-left: 0px;
    
}
}
.community-head{
    font-size: clamp(18px,2vw, 36px);
    line-height: 0.5;
    font-weight: bolder;
    color: $dark4;
    p {
        font-size: clamp(12px,1.2vw, 24px);  
        color: $gray6;
        font-weight: normal;
    }
}
.panel-four-wrapper{
    background-color: $gray3;
}
.community-wrapper{
    display:flex;
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: column;
    gap: 27px;
    .community-row{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 55px;

        .community-download{
            img{
                width: clamp(100px,12vw,300px);
                height: fit;
                &:hover{
                    transform:scale(1.08);
                }
            }
        }

        .community-feedback{
            padding-bottom: 6px;
            button{
                width: clamp(100px,12vw,300px);
                background-color: $gray5;
                border: none;
                border-radius: clamp(4px, 0.4vw, 8px);
                color: $dark6;
                display:flex;
                justify-content: space-around;
                align-items: center;
                font-size: clamp(8px, 1.1vw, 20px);
                font-weight: 600;
                &:hover{
                    transform:scale(1.08);
                }
                img{
                    width: clamp(12px,1.5vw,25px);
                    height:auto;
                }
            }

        }

        
    }
}

@media (max-width: 480px) {
    .intro-wrapper{
        .intro-container{
            width: 67vw;
    
        }
        .intro-buttons{
    
        }
    }
    .spacer-nav{
        width: 100%;
        height: 7vh
    }
    }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }



    * {
        transition: all 0.25s ease-out;
      }
      
