@import '../../styles/core';
body::before {
  content: url('/assets/coffeebefore.png') url('/assets/coffeeafter.png') url('/assets/kitchenbefore.png') url('/assets/kitchenafter.png') 
  url('/assets/livingbefore.png') url('/assets/livingafter.png');
  display: none;
}



.vision-pro-container {
  width: 80vw;
  height: auto;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  margin: auto;
  
  .masked-content, .masked-content-after {
    transform: scale(1.62);
    position: absolute;
    top: 7px;
    left: -19px;
    
    width: 100%;
    height: 100%;
    background-size: 65%;
    background-position: center 50%;
    mask-image: url('/assets/inside_content.png');
    -webkit-mask-image: url('/assets/inside_content.png');
    background-color: black;
    mask-size: cover;
    mask-position: center; 
    -webkit-mask-size: cover;
    will-change: transform, background-image, opacity, background-position;

  }

  .masked-content {
    opacity: 1;
    &.look-around-kitchen{
      background-image: url('/assets/kitchenbefore.webp'); // Adjust path

    }
    &.look-around-living{
      background-image: url('/assets/livingbefore.webp'); // Adjust path

    }
    &.look-around-coffee{
      background-image: url('/assets/coffeebefore.webp'); // Adjust path

    }
  }

  .masked-content-after {
    opacity: 1;
    &.look-around-kitchen{
      background-image: url('/assets/kitchenafter.webp'); 
      animation: mask-fade-in 2.5s ease-in, look-around-kitchen 8s forwards 2.5s cubic-bezier(0.42, 0, 0.58, 1);
    }
    &.look-around-living{
      background-image: url('/assets/livingafter.webp'); 
      animation: mask-fade-in 2.5s ease-in, look-around-kitchen 8s forwards 2.5s cubic-bezier(0.42, 0, 0.58, 1);
    }
    &.look-around-coffee{
      background-image: url('/assets/coffeeafter.webp'); 
      animation: mask-fade-in 2.5s ease-in, look-around-kitchen 8s forwards 2.5s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }

  .vision-pro-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    z-index: 2;
  }
}

.wrap{
  height: auto;
  width: auto;
  background: $gray4;
  padding-top: 3%;
  padding-right: 3%;
  padding-left: 3%;
  padding-bottom: 3%;
  border-radius: 10px;
}
.select{
  color: $dark5;
  font-size: clamp(14px, 2.1vw, 31px);
  width: 100%;
  height: 3vh;
  font-weight: 400;
  display:flex;
  justify-content:center;
  align-items:center;
  
}
  .thumbnail-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    

    img {
      width: 155px;
      border-radius: 10px;
      height: auto;
      cursor: pointer;
      &:hover{
        width: 170px;
        background-image: url('/assets/livingbefore.png');
      }
    }
  }
  .button-container {
    text-align: center;
    display: flex;
    justify-content:space-between;;
    width:auto;
    button {
      width: 155px;
      line-height: 0.2;
      background-color: transparent; // Example color
      color: $dark5;
      text-align: start;
      display: block;
      border: none;
      border-radius: 5px;
      font-weight: normal;
      cursor: pointer;
      font-size: clamp(8px, 1.6vw, 17px); // Example font size
      
      &:hover {
        background-color: $gray2; // Darker shade on hover
      }
  
      &:focus {
        outline: none;
      }
      .sub-title-by{
      font-size: clamp(7px, 1.1vw, 13px); // Example font size
        font-weight: thin;
        color: $gray1;
        
      }
    }
  }
  
  .demo-controls{
    height:auto;
    width: 500px;
    padding: 15px;
    border-radius: 10px;
    background-color: $gray4;
  }

  .mask-header{
    width: 100%;
    font-size: clamp(7px,1.25vw,14px);
    height: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

@keyframes mask-fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Start fading out but leave slightly visible for overlap */
  }
  100% {
    opacity: 0;
  }
}

@keyframes mask-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  @keyframes look-around-kitchen {
    0%, 10% {
      background-position: center center;
    }
    10%, 21% {
      background-position: center 60%;
    }
    21%, 26% {
      background-position: center 60%;
    }
    26%, 33% {
      background-position: center 20%;
    }
    33%, 44% {
      background-position: 70% 20%;
    }
    44%, 51% {
      background-position: 40% 20%;
    }
    51%, 60% {
      background-position: 65% -23%;
    }
    60%, 70% {
      background-position: 40% -23%;
    }
    70%, 80% {
      background-position: center 40%;
    }
    80%, 90% {
      background-position: center 40%;
    }
    90%, 100% {
      background-position: center -20%;
    }
  }
  @keyframes look-around-kitchen-mobile{

    0%, 50% {
      background-image: url('/assets/kitchenbefore.webp');
      background-position: center 20%;
    }
    50%, 100% {
      background-image: url('/assets/kitchenafter.webp');
      background-position: center 20%;
    }


  }

  @keyframes look-around-coffee {
    0%, 10% {
      background-position: center -20%;
    }
    10%, 21% {
      background-position: center 60%;
    }
    21%, 26% {
      background-position: center 60%;
    }
    26%, 33% {
      background-position: center 20%;
    }
    33%, 44% {
      background-position: 70% 20%;
    }
    44%, 51% {
      background-position: 40% 20%;
    }
    51%, 60% {
      background-position: 65% -23%;
    }
    60%, 70% {
      background-position: 40% -23%;
    }
    70%, 80% {
      background-position: center 40%;
    }
    80%, 90% {
      background-position: center 40%;
    }
    90%, 100% {
      background-position: center -20%;
    }
  }
//(x,y)
  @keyframes look-around-living {
    0%, 10% {
      background-position: center -20%;
    }
    10%, 21% {
      background-position: center 60%;
    }
    21%, 26% {
      background-position: center 60%;
    }
    26%, 33% {
      background-position: center 20%;
    }
    33%, 44% {
      background-position: 70% 20%;
    }
    44%, 51% {
      background-position: 40% 20%;
    }
    51%, 60% {
      background-position: 65% -23%;
    }
    60%, 70% {
      background-position: 40% -23%;
    }
    70%, 80% {
      background-position: center 40%;
    }
    80%, 90% {
      background-position: center 40%;
    }
    90%, 100% {
      background-position: center -20%;
    }

  }

  
  @media (max-width: 1023px) {

    }

    @media (max-width: 767px) {
      .wrap{
        padding: 2%;
      }
      .vision-pro-container {
        max-width: 350px;
        max-height: 191px;
        .masked-content, .masked-content-after{
          top: 8px;
          left:-10px;
        }
      }
      .demo-controls{
      width: 350px;
      padding: 2%;
      }
      .thumbnail-container{
        img {
          width: 110px;
          &:hover{
            width: 120px;
          }
        }
      }
      .button-container{
        button{
          width: 110px;
          font-size: 10px;
          .sub-title-by{
            font-size: 8px;
          }
        }
      }
    }
  
   @media (max-width: 480px) {
    .demo-controls{
      width: 350px;
    }

  

          }